const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic4.png",
    text: "Medical College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic5.png",
    text: "Medical College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic6.png",
    text: "Medical College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic7.png",
    text: "Medical College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic8.png",
    text: "Auditorium and Sports Complex",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic9.png",
    text: "Auditorium and Sports Complex",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic10.png",
    text: "Auditorium and Sports Complex",
  },

  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic11.png",
    text: "College of Physiotherapy",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic12.png",
    text: "College of Physiotherapy",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic13.png",
    text: "College of Physiotherapy",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic14.png",
    text: "College of Physiotherapy",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic15.png",
    text: "Dental College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic16.png",
    text: "Dental College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic17.png",
    text: "Dental College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic18.png",
    text: "Dental College",
  },

  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic1.png",
    text: "Administrative Block, Engg. College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic2.png",
    text: "Administrative Block, Engg. College",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic3.png",
    text: "Administrative Block, Engg. College",
  },

  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic19.png",
    text: "University Campus",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic20.png",
    text: "University Campus",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic21.png",
    text: "University Campus",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/vikhepatilfoundation/pic22.png",
    text: "University Campus",
  },
];

export default data;
